import React from "react"
import { Router } from '@reach/router'

import Index from "./index"
import Error from "./404"

const App = () => (
    <Router>
      <Index path='/' />
      <Error path='^\/?404\/?$' />
    </Router>
)

export default App
